import { Helmet } from 'react-helmet';
import posthog from 'posthog-js';
import { Slide, toast } from 'react-toastify';
import React, { memo, useEffect } from 'react';
import { Home, Dashboard, FileText } from 'tabler-icons-react';
import ModalRegistrar from '../../modals/ModalRegistrar';
import { NotificationsProvider, useNotifications } from '@mantine/notifications';
import { MantineProvider, useMantineTheme, DEFAULT_THEME } from '@mantine/core';
import Tracker from '@openreplay/tracker';

/*
const tracker = new Tracker({
  projectKey: "lYq9ZX86tIO39ffz1SKK",
  ingestPoint: "https://relay.boardsi.com/ingest",
});
*/

const Wrapper = ({ children }) => {

  var theme = useMantineTheme( DEFAULT_THEME );
  theme.colorScheme = 'dark';

  if(process.browser) {
    posthog.init( 'phc_uylFOhtUi17S3jKuqJzZwSMAVeBWla32wIbAO6PwExY', { api_host: 'https://ph.boardsi.com' } );
  }

  useEffect(() => {
    /*
    toast.configure({
      role: 'alert',
      hideProgressBar: false,
      transition: Slide,
      closeButton: true,
      position: 'bottom-right',
      pauseOnFocusLoss: false,
    });
    */

  //  tracker.start();

    const script = document.createElement( 'script' );
    script.src = "https://unpkg.com/panzoom@9.4.0/dist/panzoom.min.js";
    script.async = false;

    document.body.appendChild( script );

    return () => {
      document.body.removeChild( script );
    }
  }, []);

  return (
    <>
      <MantineProvider theme={{ theme }} styles={{

      }}>
      <NotificationsProvider position="bottom-right" zIndex={2077}>
          
      <Helmet>
        <html lang="en" />
        <title>Boardsi Board Document</title>
        <meta name="description" content="A Board Document Builder by Boardsi." />
        <link rel="canonical" href="https://document.boardsi.com" />
        <meta property="og:url" content="https://document.boardsi.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Keep your Board document or CV future-ready. In a fast-paced world, it's not easy to keep everything up to date. With boardsi Board Document, you dont have to worry about being caught off guard. Always be ready to stand out and above the crowd when applying for Board Positions."
        />
        <meta property="og:image" content="http://rxresu.me/images/share.png" />
      </Helmet>
              {children}
          <ModalRegistrar />
        
        </NotificationsProvider>
      </MantineProvider>
    </>
  );
};

export default memo(Wrapper);

export async function getServerData() {}