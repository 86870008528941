import { Modal, useMantineTheme, Button } from '@mantine/core';
import { MdClose } from 'react-icons/md';
import { isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { forwardRef, memo, useImperativeHandle, useState } from 'react';
//import Button from '../components/shared/Button';
import { handleKeyUp } from '../utils';
import * as styles from './BaseModal.module.css';

const BaseModal = forwardRef(
  ({ title, state, children, action, hideActions = false, onDestroy }, ref) => {
    const [open, setOpen] = state;
    const { t } = useTranslation();

    const [animation, setAnimation] = useState("slide-down");

    const [ mounted, setMounted] = useState(true)


    const theme = useMantineTheme();
    theme.colorScheme = 'dark';

    const handleClose = () => {
      setAnimation("slide-down");
      setOpen(false);

      setTimeout(() => {
        isFunction(onDestroy) && onDestroy();
      }, 250);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    return (
      <Modal
        transition={animation}
        duration={300}
        outline="true"
        radius="md"
        opened={open}
        overlayColor={theme.colors.dark[ 6 ]}
        overlayOpacity={0.55}
        style={{border: `1px solid ${theme.colors.dark[5]}`}}
        onClose={handleClose}
        title={title}
        size="lg"
        shadow={theme.shadows.md}
      >
          <div className={styles.modal}>
            <div className={styles.body}>{children}</div>

            {!hideActions && (
              <div className={styles.actions}>
                <Button type="boardsi" variant="outline" color="gray" className="mr-8" onClick={handleClose}>
                  {t('shared.buttons.cancel')}
                </Button>

                {action}
              </div>
            )}
          </div>
      </Modal>
    );
  },
);

export default memo(BaseModal);
