import { useNotifications } from '@mantine/notifications';
import { useMantineTheme } from '@mantine/core';
import { Check, X, IconMail } from 'tabler-icons-react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import BaseModal from './BaseModal';
//import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
import utils from '../utils';

const AuthModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);
  const [isLoadingAnonymous, setLoadingAnonymous] = useState(false);
  const [ isLoadingEmail, setLoadingEmail ] = useState( false );
  const [ visible, setVisible ] = useState( false );

  const { emitter, events } = useContext(ModalContext);
  const { user, loginWithGoogle, loginAnonymously, loginWithEmail, logout } = useContext(UserContext);

  const notifications = useNotifications();
  const theme = useMantineTheme();
  theme.colorScheme = 'dark';

  useEffect(() => {
    const unbind = emitter.on(events.AUTH_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    await loginWithGoogle();
    setLoadingGoogle(false);
  };
  

  const handleSignInAnonymously = async () => {
    setLoadingAnonymous(true);
    await loginAnonymously();
    setLoadingAnonymous(false);
  };

  const handleSignInEmail = async () => {
    setLoadingEmail( true );
    setVisible(true);
    const emailRequest = await loginWithEmail();
    console.log("authmodal -> ", emailRequest);
    setVisible( false );
    setLoadingEmail( false );
    notifications.showNotification({title: "Link Boardsi Account", color: "green",  message: "We just sent you a login link to the email you have on file with boardsi."})
  };

  const handleGotoApp = () => {
    navigate('/app/dashboard');
    setOpen(false);
  };

  const getTitle = () =>
    user
      ? t('modals.auth.welcome', { name: user.displayName || 'Agent 47' })
      : "Boardsi Sign In"

  const getMessage = () =>
    user ? t('modals.auth.loggedInText') : t('modals.auth.loggedOutText');

  
  const loggedInAction = (
    <>
      <Button outline className="mr-8" onClick={logout}>
        {t('shared.buttons.logout')}
      </Button>
      <Button title="" onClick={handleGotoApp}>
        {t('landing.hero.goToApp')}
      </Button>
    </>
  );

  // const loggedOutAction = (
  //   <div className="flex">
  //     <Button isLoading={isLoadingGoogle} onClick={handleSignInWithGoogle}>
  //       {t('modals.auth.buttons.google')}
  //     </Button>
  //     <Button
  //       className="ml-8"
  //       isLoading={isLoadingAnonymous}
  //       onClick={handleSignInAnonymously}
  //     >
  //       {t('modals.auth.buttons.anonymous')}
  //     </Button>
  //   </div>
  // );

  const loggedOutAction = (
    <div className="flex">
      {visible ? <><Button loading>
        Sending Login Email..
      </Button></> : <><Button variant="filled" sx={( theme ) => ( {
        backgroundColor: theme.colors.indigo[ 9 ],
        '&:hover': {
          backgroundColor: theme.colors.indigo[ 5 ],
        },
      } )}  onClick={handleSignInEmail}>
        Send Login Email
      </Button> </>}

    </div>
  );


  return (
    <BaseModal
      title={getTitle()}
      state={[open, setOpen]}
      action={user ? loggedInAction : loggedOutAction}
    >
        <p className="leading-loose">{getMessage()}</p>
    </BaseModal>
  );
};

export default memo(AuthModal);
